<template>
  <div class="container">
    <div class="row chat-window col-3" id="chat_window_1" style="margin-left:10px;">
      <div class="col-12">
        <span v-if="!open && unreadNo > 0" class="unread">{{ unreadMsg }}</span>
        <div class="panel panel-default">
          <div @click="toggleChat()" class="panel-heading top-bar">
            <h5 id="chat-header">
              <p id="chat-caption">{{ $t('footer.chat') }}</p>
              <span v-if="!open" class="chat-logo-container">
                <img id="chat-logo" src="../assets/img/chat/chat-logo.svg" alt="" />
              </span>
              <span v-if="open" class="chat-logo-container">
                <img id="chat-logo" src="../assets/img/chat/chat-minimize.svg" alt="" />
              </span>
            </h5>
          </div>
          <!-- Toggleable -->
          <div v-show="open">
            <div
              id="dialog-container"
              class="panel-body msg_container_base"
              :class="{ 'hidden-chat-box': !open }"
              ref="chat-box"
            >
              <form v-on:submit.prevent @submit="registerChat()" v-if="showRegistry">
                <br />
                <p class="text-primary">
                  Please enter the below information in order to make it easy to contact you back.
                </p>
                <div class="form-group">
                  <label>Name</label>
                  <input
                    v-model.trim="chatInfo.name"
                    type="text"
                    class="form-control"
                    placeholder="your name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label>Mobile</label>
                  <input
                    v-model.trim="chatInfo.phone"
                    type="tel"
                    class="form-control"
                    placeholder="your mobile number"
                    required
                  />
                </div>
                <button type="submit" class="btn btn-dark btn-block">
                  Start Chatting
                </button>
              </form>
              <div v-else>
                <div v-for="(m, index) in chat" :key="index">
                  <div v-if="m.is_client">
                    <div class="row msg_container base_sent">
                      <div class="col-10">
                        <div class="messages msg_sent">
                          <p>{{ m.message }}</p>
                          <time datetime="2009-11-13T20:00"> Me • {{ calcTime(m.time) }} </time>
                        </div>
                      </div>
                      <div class="col-2 avatar">
                        <img class="img-responsive" src="../assets/img/chat/user-avatar.jpg" />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="row msg_container base_receive">
                      <div class="col-2 avatar">
                        <img class="img-responsive" src="../assets/img/chat/employee-avatar.jpg" />
                      </div>
                      <div class="col-10">
                        <div class="messages msg_receive">
                          <p>{{ m.message }}</p>
                          <time datetime="2009-11-13T20:00">
                            {{ m.user }} • {{ calcTime(m.time) }}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="panel-footer" v-if="!showRegistry">
              <div class="input-group" :class="{ 'hidden-chat-box': !open }">
                <input
                  id="btn-input"
                  type="text"
                  v-model="userMessage"
                  @keyup.enter="sendMessage()"
                  @focus="markRead()"
                  class="form-control input-sm chat_input"
                  ref="chat-input"
                  placeholder="Write your message here..."
                  autocomplete="off"
                />
                <span class="input-group-btn">
                  <button @click="sendMessage()" class="btn btn-dark btn-sm" id="btn-chat">
                    Send
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

function addLeadingZero(inputNumber) {
  const result = inputNumber < 10 ? `0${inputNumber.toString()}` : inputNumber.toString();
  return result;
}

export default {
  name: 'ChatBox',
  data() {
    return {
      open: false,
      connection: null,
      chat: this.$store.state.chat.conversation,
      userMessage: null,
      username: this.$store.state.user.userInfo.username,
      userId: 0,
      clientId: this.$store.state.chat.clientId,
      unreadNo: 0,
      chatInfo: {
        name: '',
        phone: '',
      },
    };
  },
  computed: {
    ...mapGetters('chat', ['conversation'], 'user', ['userInfo', 'requestErrors', 'loginName']),
    ...mapGetters('user', ['isAuthenticated', 'getChatInfo']),
    unreadMsg() {
      const result = this.open ? 0 : this.unreadNo;
      return result;
    },
    showRegistry() {
      return !this.isAuthenticated && !this.getChatInfo.phone;
    },
    chatData() {
      return this.chatInfo.phone ? `${this.chatInfo.name} (${this.chatInfo.phone})` : 'none';
    },
  },
  created() {
    this.chatInfo.name = this.getChatInfo.name;
    this.chatInfo.phone = this.getChatInfo.phone;
  },
  async mounted() {
    this.$nextTick(() => {
      this.username = this.$store.state.user.userInfo.username;
      this.userId = this.$store.state.user.userInfo.id || 0;
      this.clientId = this.$store.state.chat.clientId;
      this.chat = this.$store.state.chat.conversation;
    });
  },
  methods: {
    ...mapActions('chat', ['getConversation']),
    ...mapActions('user', ['postChatInfo']),
    registerChat() {
      this.postChatInfo(this.chatInfo);
    },
    getWebsocketLink() {
      const wsScheme = window.location.protocol === 'https:' ? 'wss' : 'ws';
      let wsHost = window.location.host;
      if (wsHost === '127.0.0.1:8080' || wsHost === '127.0.0.1:8000') {
        wsHost = '127.0.0.1:8000';
      } else {
        wsHost += ':8001';
      }
      const wsLink = `${wsScheme}://${wsHost}/ws/chat/${this.clientId}/client/${this.userId}/`;
      return wsLink;
    },
    async toggleChat() {
      this.open = !this.open;
      this.unreadNo = 0;
      if (this.clientId === '0000') {
        await this.$store
          .dispatch('chat/getClientId')
          .then((this.clientId = this.$store.state.chat.clientId));
      }
      if (this.chat.length === 0) {
        await this.$store
          .dispatch('chat/getConversation')
          .then((this.chat = this.$store.state.chat.conversation));
      }
      this.loadChat();
    },
    async loadChat() {
      this.applyConnection();
      this.chat = this.$store.getters['chat/conversation'];
      this.scrollChat();
    },
    markRead() {
      const readMessage = {
        type: 'notification_read',
        message: 'notification_read',
        user: this.$store.state.user.userInfo.username,
        user_id: this.$store.state.user.userInfo.id,
        is_client: true,
        user_data: this.chatData,
      };
      try {
        this.connection.send(JSON.stringify(readMessage));
      } catch (err) {
        // console.log(err);
      } finally {
        this.userMessage = null;
      }
    },
    sendMessage() {
      if (this.userMessage) {
        const newMessage = {
          type: 'chat_message',
          message: this.userMessage,
          user: this.$store.state.user.userInfo.username,
          user_id: this.$store.state.user.userInfo.id,
          is_client: true,
          user_data: this.chatData,
        };
        if (this.connection.readyState !== 1) {
          this.$router.go();
        }
        try {
          this.connection.send(JSON.stringify(newMessage));
        } catch (err) {
          // console.log(err);
        } finally {
          this.userMessage = null;
        }
      }
    },
    applyConnection() {
      if (!this.connection) {
        this.getConversation(this.clientId);
        this.connection = new WebSocket(this.getWebsocketLink());
        this.connection.onclose = (e) => {
          setTimeout(() => {
            this.connection.reconnet(e);
          }, 1000);
        };
        this.connection.onopen = () => {};
        this.connection.onerror = () => {
          this.connection.close();
        };
        this.connection.onmessage = (event) => {
          const data = JSON.parse(event.data);
          const newMessage = {
            type: data.type,
            message: data.message,
            room: this.clientId,
            time: data.time,
            user: data.user,
            is_client: data.is_client,
            user_data: this.chatData,
          };
          if (newMessage.type === 'chat_message') {
            this.chat.push(newMessage);
            this.unreadNo += 1;
            this.scrollChat();
          }
        };
      }
    },
    calcTime(chatTime) {
      const inputTime = new Date(chatTime);
      const currentTime = new Date();
      const timeDiff = Math.round((currentTime - inputTime) / 1000, 0);
      let result = '';
      if (timeDiff < 30) {
        result = 'few sec.';
      } else if (timeDiff < 60) {
        result = `${timeDiff} sec`;
      } else if (timeDiff < 3600) {
        result = `${Math.floor(timeDiff / 60)} min`;
      } else if (timeDiff < 86400) {
        result = `${Math.floor(timeDiff / 60 / 60)} hrs`;
      } else {
        const monthNames = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];
        const timeDay = addLeadingZero(inputTime.getDate());
        const timeHour = addLeadingZero(inputTime.getHours());
        const timeMinute = addLeadingZero(inputTime.getMinutes());
        result = `${timeDay}${monthNames[inputTime.getMonth()]} ${timeHour}:${timeMinute}`;
      }
      return result;
    },
    scrollChat() {
      this.$nextTick(() => {
        const chatBox = this.$refs['chat-box'];
        if (chatBox) {
          chatBox.scroll(0, chatBox.scrollHeight);
        }
      });
    },
  },
  watch: {
    open() {
      this.$nextTick(() => {
        this.scrollChat();
      });
    },
  },
};
</script>

<style scoped>
body {
  height: 400px;
  position: fixed;
  bottom: 0;
}
.col-2,
.col-10 {
  padding: 0;
}
.panel {
  margin-bottom: 0px;
}
.chat-window {
  bottom: 0;
  position: fixed;
  float: right;
  margin-left: 10px;
}
.chat-window > div > .panel {
  border-radius: 5px 5px 0 0;
}
.icon_minim {
  padding: 2px 10px;
}
.msg_container_base {
  background: #e5e5e5;
  margin: 0;
  padding: 0 10px 10px;
  max-height: 300px;
  min-height: 300px;
  overflow-x: hidden;
  -webkit-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  -moz-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
}
.top-bar {
  background: #666;
  color: white;
  padding: 10px 10px 5px 10px;
  position: relative;
  overflow: hidden;
}
.msg_receive {
  padding-left: 0;
  margin-left: 0;
}
.msg_sent {
  padding-bottom: 20px !important;
  margin-right: 0;
}
.messages {
  background: white;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 100%;
}
.messages > p {
  font-size: 14px;
  margin: 0 0 0.2rem 0;
}
.messages > time {
  font-size: 11px;
  color: #ccc;
}
.msg_container {
  padding: 10px;
  overflow: hidden;
  display: flex;
}
img {
  display: block;
  width: 100%;
}
.avatar {
  position: relative;
}
.base_receive > .avatar:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border: 5px solid #fff;
  border-left-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0);
}

.base_sent {
  justify-content: flex-end;
  align-items: flex-end;
}
.base_sent > .avatar:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid white;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 1px 1px 2px rgba(black, 0.2);
}

.msg_sent > time {
  float: right;
}

.msg_container_base::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.msg_container_base::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.msg_container_base::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.btn-group.dropup {
  position: fixed;
  left: 0px;
  bottom: 0;
}

#chat_window_1 {
  right: 10px;
  min-width: 315px;
  max-width: 350px;
  z-index: 900;
}

.panel-heading {
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  -webkit-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  -moz-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  margin-bottom: 0;
}

#chat-logo {
  width: 30px;
}

.chat-logo-container {
  float: right;
}

.hidden-chat-box {
  min-height: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}

#chat-caption {
  display: inline-block;
  text-align: center;
  width: 80%;
  margin: 2px 0 -4px 0;
}

#btn-input {
  -webkit-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  -moz-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  padding: 20px;
  font-size: 15px;
}

#btn-chat {
  height: 40px;
  margin-top: 1px;
  margin-bottom: 1px;
  -webkit-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  -moz-box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
  box-shadow: -5px -5px 23px -8px rgba(10, 10, 10, 1);
}

#chat-header {
  margin-bottom: 0;
}

.unread {
  background-color: grey;
  color: white;
  border: 1px solid grey;
  padding: 2px 9px;
  border-radius: 50%;
  float: right;
  display: inline;
  margin-top: -25px;
  margin-left: -40px;
}
</style>
